import { useState, useEffect } from 'react'

import Header from '@components/UI/Header/Header'
import ServiceItem from '@components/UI/components/ServiceItem/ServiceItem'
import NoiseBg from '@components/UI/components/NoiseBg/NoiseBg'

import styles from './OurServices.module.sass'

import { servicesMock } from '@mock/servicesMock'

interface IProps {
  isNoiseBg?: boolean
}

const OurServices = ({ isNoiseBg = false }: IProps) => {
  const [services, setServices] = useState([])

  useEffect(() => {
    //! for last odd item to put outside of the grid
    const tempServices =
      servicesMock.length % 2 !== 0
        ? servicesMock.slice(0, servicesMock.length - 1)
        : servicesMock

    setServices(tempServices)
  }, [])

  return (
    <div className={styles.container} id="services">
      {isNoiseBg && <NoiseBg zIndex={0} />}
      <div className={styles.headerContainer}>
        <Header color="yellow">
          наші <span>послуги</span>
        </Header>
      </div>

      <div className={styles.servicesContainer}>
        {services.map((item) => (
          <ServiceItem
            key={item.id}
            isBtnDisabled={item.isBtnDisabled}
            title={item.title}
            description={item.description}
            imgUrl={item.imgUrl}
            alt={item.alt}
            link={item.link}
            color={item.color}
          />
        ))}
      </div>

      {
        //! for last odd item to put outside of the grid
        servicesMock.length % 2 !== 0 && (
          <div className={styles.lastItemContainer}>
            <ServiceItem
              isBtnDisabled={
                servicesMock[servicesMock.length - 1].isBtnDisabled
              }
              title={servicesMock[servicesMock.length - 1].title}
              description={servicesMock[servicesMock.length - 1].description}
              imgUrl={servicesMock[servicesMock.length - 1].imgUrl}
              alt={servicesMock[servicesMock.length - 1].alt}
              link={servicesMock[servicesMock.length - 1].link}
              color={servicesMock[servicesMock.length - 1].color}
            />
          </div>
        )
      }
    </div>
  )
}

export default OurServices
