import { ReactSVG } from 'react-svg'

import { useTheme } from '@hooks/useTheme'

import Header from '../Header/Header'

import styles from './HeaderWithDescription.module.sass'

interface IProps {
  header: string | JSX.Element
  description: string | JSX.Element
  svgUrl?: string
  color?: 'yellow' | 'blue' | 'blueYellow'
  dividerColor: 'yellow' | 'blue' | 'blueYellow'
  iconClassName?: string
}

const HeaderWithDescription = ({
  header,
  description,
  svgUrl,
  color = 'yellow',
  dividerColor = 'yellow',
  iconClassName,
}: IProps) => {
  const { themeStyles } = useTheme()

  const stylesDefault = {
    blue: {
      descriptionContainer: styles.descriptionContainerBlue,
    },

    yellow: {
      descriptionContainer: styles.descriptionContainer,
    },

    blueYellow: {
      descriptionContainer: styles.descriptionContainerBlueYellow,
    },
  }

  const stylesDivider = {
    blue: {
      backgroundColorTop: themeStyles.colors.accent2,
      backgroundColorBottom: themeStyles.colors.accent2,
    },

    yellow: {
      backgroundColorTop: themeStyles.colors.accent,
      backgroundColorBottom: themeStyles.colors.accent,
    },

    blueYellow: {
      backgroundColorTop: themeStyles.colors.accent2,
      backgroundColorBottom: themeStyles.colors.accent,
    },
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <Header color={color}>{header}</Header>

        {svgUrl && (
          <div
            className={
              iconClassName ? styles[iconClassName] : styles.iconContainer
            }
          >
            <ReactSVG src={svgUrl} />
          </div>
        )}
      </div>

      <div
        className={`${styles.descriptionContainer} ${stylesDefault[color].descriptionContainer}`}
      >
        <div
          className={styles.divider}
          style={{
            backgroundColor: stylesDivider[dividerColor].backgroundColorTop,
          }}
        ></div>
        <div
          className={styles.divider2}
          style={{
            backgroundColor: stylesDivider[dividerColor].backgroundColorBottom,
          }}
        ></div>

        <p>{description}</p>
      </div>
    </div>
  )
}

export default HeaderWithDescription
