import SmallHeader from '@components/UI/SmallHeader/SmallHeader'

import styles from '@components/UI/components/TextTwoColumns/TextTwoColumns.module.sass'

export const servicesMock = [
  {
    id: 1,
    title: 'Доставка посилок',
    description:
      'Сервіс доставки посилок між Україною та країнами Європи.\nГарантуємо в короткі терміни та з максимальною відповідальностю доставити посилку на будь-яку адресу.',
    imgUrl: '/images/services/delivery.webp',
    alt: 'delivery',
    link: '/delivery',
    color: 'yellow',
  },
  {
    id: 2,
    title: 'Пасажирські перевезення',
    description:
      'Сервіс пасажирських перевезень між Україною та країнами Європи.\nПрацюємо за новітнім методом: автобусні рейси з адреси на адресу.',
    imgUrl: '/images/services/transportation.webp',
    alt: 'passenger',
    link: '/transportation',
    color: 'blue',
  },
  {
    id: 3,
    isBtnDisabled: true,
    title: 'Покупки закордоном',
    description:
      'Придбаємо необхідні Вам речі з Європи та Америки і забезпечимо швидку, надійну, недорогу доставку в Україну.',
    imgUrl: '/images/services/shopping.webp',
    alt: 'shopping',
    link: '',
    color: 'yellow',
  },
  {
    id: 4,
    isBtnDisabled: true,
    title: 'Оренда транспорту',
    description:
      'Сервіс пасажирських перевезень між Україною та країнами Європи.\nПрацюємо за новітнім методом: автобусні рейси з адреси на адресу.',
    imgUrl: '/images/services/rent.webp',
    alt: 'rent',
    link: '',
    color: 'blue',
  },
  {
    id: 5,
    isBtnDisabled: true,
    title: 'Дошка сторонніх перевізників',
    description:
      'Сервіс пасажирських перевезень між Україною та країнами Європи.\nПрацюємо за новітнім методом: автобусні рейси з адреси на адресу.',
    imgUrl: '/images/services/board.webp',
    alt: 'board',
    link: '',
    color: 'blue',
  },
]

export const descriptionMock = [
  {
    id: 0,
    textContent: (
      <>
        <div className={styles.headerContainer}>
          <SmallHeader>
            Доставки посилок між Україною та країнами Європи
          </SmallHeader>
        </div>

        <div className={styles.textContainer}>
          <p>
            Ми забезпечуємо широкий вибір маршрутів, що охоплюють найбільш
            популярні напрямки між країнами Європи.
          </p>

          <p>
            Наші маршрути включають доставку з України до Словаччини та Чехії, а
            також в зворотному напрямку.
          </p>

          <p>
            Наші кур'єри - досвідчені фахівці, які забезпечують швидку та
            надійну доставку вашої посилки. Вони знають, наскільки важлива ваша
            посилка та зобов'язані забезпечити її безпеку та цілісність.
          </p>

          <p>
            Ми з радістю допоможемо забезпечити доставку вашої посилки з
            максимальним комфортом та надійністю. Крім того, ми постійно додаємо
            нові маршрути, щоб забезпечити максимальну зручність та ефективність
            наших послуг для наших клієнтів
          </p>
        </div>
      </>
    ),
  },
  {
    id: 1,
    textContent: (
      <>
        <div className={styles.headerContainer}>
          <SmallHeader>
            Пасажирські перевезення між Україною та країнами Європи
          </SmallHeader>
        </div>

        <div className={styles.textContainer}>
          <p>
            Наші автобуси обладнані зручними сидіннями та надійними системами
            безпеки, що забезпечують комфортну та безпечну поїздку. Наші водії -
            професійні та досвідчені фахівці, які забезпечують найвищий рівень
            обслуговування наших пасажирів.
          </p>

          <p>
            Маршрути включають такі напрямки, наприклад: Івано-Фран-ківськ -
            Кошице, Краків - Львів, Відень - Київ, Рим - Львів та багато інших.
            Ми постійно оновлюємо наші маршрути, щоб забезпечити найкращі
            транспортні зв'язки між країнами Європи.
          </p>

          <p>
            Якщо ви шукаєте надійного та комфортного перевізника між країнами
            Європи, зверніться до нас. Ми з радістю допоможемо забезпечити вашу
            поїздку з максимальним комфортом та безпекою.
          </p>
        </div>
      </>
    ),
  },
]
