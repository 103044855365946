import { ReactSVG } from 'react-svg'
import Image from 'next/image'

import styles from './FeedbackSliderItem.module.sass'

interface IProps {
  name: string
  stars: number
  fromCountry: string
  toCountry: string
  imgUrl: string
  comment: string
  [key: string]: any
}

const FeedbackSliderItem = ({
  name,
  stars,
  fromCountry,
  toCountry,
  imgUrl,
  comment,
}: IProps) => {
  const starIcons = []
  const emptyStars = 5 - stars

  const getStars = () => {
    stars = Math.round(stars)
    if (stars > 5) stars = 5
    if (stars < 0) stars = 0

    for (let i = 0; i < stars; i++) {
      starIcons.push(
        <div className={styles.iconContainer} key={i}>
          <ReactSVG src="/icons/star.svg" />
        </div>
      )
    }

    for (let i = 0; i < emptyStars; i++) {
      starIcons.push(
        <div className={styles.iconContainer} key={i + 5}>
          <ReactSVG src="/icons/star-empty.svg" />
        </div>
      )
    }

    return starIcons
  }

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.topContainer}>
          <div className={styles.topLeftContainer}>
            <div className={styles.imgContainer}>
              <Image
                src={imgUrl}
                alt="feedback"
                layout="fill"
                objectFit="contain"
              />
            </div>

            <div className={styles.nameAndStarsContainer}>
              <div className={styles.nameContainer}>
                <p>{name}</p>
              </div>

              <div className={styles.starsContainer}>
                {getStars()}

                <p>({stars}/5)</p>
              </div>
            </div>
          </div>

          <div className={styles.topRightContainer}>
            <div className={styles.fromContainer}>
              <p>Звідки</p>
              <div className={styles.flagImgContainer}>
                <Image
                  src={`/images/flags/${fromCountry}.png`}
                  alt="feedback"
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            </div>

            <div className={styles.line}></div>

            <div className={styles.toContainer}>
              <p>Куди</p>
              <div className={styles.flagImgContainer}>
                <Image
                  src={`/images/flags/${toCountry}.png`}
                  alt="feedback"
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.commentContainer}>
          <p>{comment}</p>
        </div>
      </div>
    </div>
  )
}

export default FeedbackSliderItem
