import Image from 'next/image'
import Link from 'next/link'

import SmallHeader from '@components/UI/SmallHeader/SmallHeader'
import Button from '@components/UI/Button/Button'

import styles from './ServiceItem.module.sass'

interface IProps {
  title: string
  isBtnDisabled?: boolean
  description: string
  imgUrl: string
  alt: string
  link: string
  color?: string
  [key: string]: any
}

const ServiceItem = ({
  title,
  isBtnDisabled = false,
  description,
  imgUrl,
  alt,
  link,
  color = 'blue',
}: IProps) => {
  return (
    <div
      className={
        styles.container
        // color === 'yellow'
        //   ? `${styles.container} ${styles.yellow}`
        //   : `${styles.container} ${styles.blue}`
      }>
      <div className={styles.bgContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.titleContainer}>
            <SmallHeader>{title}</SmallHeader>
          </div>

          <div className={styles.descriptionContainer}>
            <p>{description}</p>
          </div>

          <div className={styles.btnLinkContainer}>
            <Button
              className={isBtnDisabled ? 'newDisabled' : 'newInvertedPrimary'}
              href={link}>
              Обрати послугу
            </Button>
          </div>
        </div>

        <div className={styles.animationContainer}>
          <div className={styles.imgContainer}>
            <Image src={imgUrl} alt={alt} layout="fill" objectFit="contain" />
          </div>

          {/* <div
          className={
            color === 'yellow'
              ? `${styles.circleYellow}`
              : `${styles.circleBlue}`
          }></div>

        <div
          className={
            color === 'yellow' ? `${styles.blurYellow}` : `${styles.blurBlue}`
          }></div> */}
        </div>
      </div>
    </div>
  )
}

export default ServiceItem
