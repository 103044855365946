export const feedbacksMock = [
  {
    id: 1,
    name: 'Володимир',
    stars: 5.5,
    fromCountry: 'Ukraine',
    toCountry: 'Czech Republic',
    comment:
      'Однозначно рекомендую Perevozka. Посилку забрав кур’єр з адреси, в зручний для мені час. Дитина отримала вчасно пакунок з Дніпра у Кошице. Також, відзначу те, що кур’єр заніс посилку дитині прямо під двері. Однозначно рекомендую Perevozka. Посилку забрав кур’єр з адреси, в зручний для мені час. Дитина отримала вчасно пакунок з Дніпра у Кошице. Також, відзначу те, що кур’єр заніс посилку дитині прямо під двері.',
    imgUrl: '/images/feedbacks/img1.png',
  },
  {
    id: 2,
    name: 'Ксенія',
    stars: 2,
    fromCountry: 'Ukraine',
    toCountry: 'Czech Republic',
    comment:
      'Дуже задоволена роботою компанії. Передавала посилку з Брно до Києва, доставка тривала 2 дні. Здивувало те, що посилку забрали одразу з під моєї адреси.',
    imgUrl: '/images/feedbacks/img2.png',
  },
  {
    id: 3,
    name: 'Олексій',
    stars: 5,
    fromCountry: 'Ukraine',
    toCountry: 'Czech Republic',
    comment:
      'Рекомендую дану організацію. Надсилав важливий документ з Харкова до Праги. Весь час доставки комунікував з диспетчером, який завжди був онлайн, та звітував про стан посилки на даний момент.',
    imgUrl: '/images/feedbacks/img3.png',
  },
  {
    id: 4,
    name: 'Володимир',
    stars: 3,
    fromCountry: 'Ukraine',
    toCountry: 'Czech Republic',
    comment:
      'Однозначно рекомендую Perevozka. Посилку забрав кур’єр з адреси, в зручний для мені час.',
    imgUrl: '/images/feedbacks/img1.png',
  },
  {
    id: 5,
    name: 'Ксенія',
    stars: 0,
    fromCountry: 'Ukraine',
    toCountry: 'Czech Republic',
    comment:
      'Дуже задоволена роботою компанії. Передавала посилку з Брно до Києва, доставка тривала 2 дні. Здивувало те, що посилку забрали одразу з під моєї адреси.',
    imgUrl: '/images/feedbacks/img2.png',
  },
  {
    id: 6,
    name: 'Олексій',
    stars: 6,
    fromCountry: 'Ukraine',
    toCountry: 'Czech Republic',
    comment:
      'Рекомендую дану організацію. Надсилав важливий документ з Харкова до Праги. Весь час доставки комунікував з диспетчером, який завжди був онлайн, та звітував про стан посилки на даний момент.',
    imgUrl: '/images/feedbacks/img3.png',
  },
]

export const feedbacksTransportationMock = [
  {
    id: 1,
    name: 'Тарас',
    stars: 5,
    fromCountry: 'Ukraine',
    toCountry: 'Czech Republic',
    comment:
      'Дякую за комфортну поїздку компанії Перевозка. Хочу відзначити хороше ставлення персоналу автобуса маршруту Київ-Братислава. Та організовані зупинки під час подорожі. ',
    imgUrl: '/images/feedbacks/img4.png',
  },
  {
    id: 2,
    name: 'Олена',
    stars: 5,
    fromCountry: 'Ukraine',
    toCountry: 'Czech Republic',
    comment:
      'Дякую за відмінну роботу організації Перевозка. Не могла подумати, що ціна за проїзд від Стрия до Брна може бути така низька. Щей пригостили кавою у салоні автобуса.',
    imgUrl: '/images/feedbacks/img5.png',
  },
  {
    id: 3,
    name: 'Олексій',
    stars: 3,
    fromCountry: 'Ukraine',
    toCountry: 'Czech Republic',
    comment:
      'Рекомендую даного перевізника. Ніколи не думав, що автобусний переїзд може тривати так швидко.  Окремо дякую водію маршруту Прага-Львів за додаткову зупинку, по моїй потребі.',
    imgUrl: '/images/feedbacks/img6.png',
  },
  {
    id: 4,
    name: 'Тарас',
    stars: 4,
    fromCountry: 'Ukraine',
    toCountry: 'Czech Republic',
    comment:
      'Дякую за комфортну поїздку компанії Перевозка. Хочу відзначити хороше ставлення персоналу автобуса маршруту Київ-Братислава. Та організовані зупинки під час подорожі. ',
    imgUrl: '/images/feedbacks/img4.png',
  },
  {
    id: 5,
    name: 'Олена',
    stars: 6,
    fromCountry: 'Ukraine',
    toCountry: 'Czech Republic',
    comment:
      'Дякую за відмінну роботу організації Перевозка. Не могла подумати, що ціна за проїзд від Стрия до Брна може бути така низька. Щей пригостили кавою у салоні автобуса.',
    imgUrl: '/images/feedbacks/img5.png',
  },
  {
    id: 6,
    name: 'Олексій',
    stars: 0,
    fromCountry: 'Ukraine',
    toCountry: 'Czech Republic',
    comment:
      'Рекомендую даного перевізника. Ніколи не думав, що автобусний переїзд може тривати так швидко.  Окремо дякую водію маршруту Прага-Львів за додаткову зупинку, по моїй потребі.',
    imgUrl: '/images/feedbacks/img6.png',
  },
]
