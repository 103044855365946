import Image from 'next/image'

import styles from './NoiseBg.module.sass'

interface IProps {
  zIndex?: number
}

const NoiseBg = ({ zIndex = -1 }: IProps) => {
  return (
    <div className={styles.container} style={{ zIndex }}>
      <div className={styles.imgContainer}>
        <Image src="/images/noise.png" layout="fill" />
      </div>
    </div>
  )
}

export default NoiseBg
