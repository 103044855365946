import HeaderWithDescription from '@components/UI/HeaderWithDescription/HeaderWithDescription'
import FeedbackSlider from '@components/UI/components/FeedbackSlider/FeedbackSlider'
import NoiseBg from '../NoiseBg/NoiseBg'

import styles from './Feedbacks.module.sass'

interface IProps {
  data: {
    id: number
    name: string
    stars: number
    toCountry: string
    fromCountry: string
    comment: string
    imgUrl: string
  }[]
  header?: string | JSX.Element
  description?: string
  svgUrl?: string
  color?: 'yellow' | 'blue' | 'blueYellow'
  dividerColor?: 'yellow' | 'blue' | 'blueYellow'
  isPaddingTop?: boolean
  isNoiseBg?: boolean
}

const Feedbacks = ({
  data,
  header = 'відгуки',
  description = 'Відгуки ви зможете залишити в особистому кабінеті тільки після користання послугами.',
  svgUrl,
  color = 'yellow',
  dividerColor = 'blueYellow',
  isPaddingTop = true,
  isNoiseBg = false,
}: IProps) => {
  return (
    <div
      className={isPaddingTop ? styles.containerPadding : styles.container}
      id="feedbacks">
      {isNoiseBg && <NoiseBg />}
      <div className={styles.headerContainer}>
        <HeaderWithDescription
          header={header}
          description={description}
          svgUrl={svgUrl}
          color={color}
          dividerColor={dividerColor}
        />
      </div>

      <div className={styles.sliderContainer}>
        <FeedbackSlider data={data} />
      </div>
    </div>
  )
}

export default Feedbacks
