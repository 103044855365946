import Slider from 'react-slick'

import NextArrow from './NextArrow'
import PrevArrow from './PrevArrow'
import FeedbackSliderItem from '../FeedbackSliderItem/FeedbackSliderItem'

import styles from './FeedbackSlider.module.sass'

interface IProps {
  data: {
    id: number
    name: string
    stars: number
    fromCountry: string
    toCountry: string
    comment: string
    imgUrl: string
  }[]
}

const FeedbackSlider = ({ data }: IProps) => {
  const settings = {
    customPaging: function () {
      return (
        <a>
          <div
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              margin: '0 5px',
            }}></div>
        </a>
      )
    },
    infinite: true,
    slidesToShow: 2,
    speed: 500,
    rows: 2,
    slidesPerRow: 1,
    dots: false,
    // autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          dotsClass: 'slick-dots slick-thumb',
        },
      },
    ],
  }

  return (
    <div className={styles.container}>
      <Slider {...settings}>
        {data.map((feedback) => (
          <FeedbackSliderItem
            key={feedback.id}
            name={feedback.name}
            stars={feedback.stars}
            fromCountry={feedback.fromCountry}
            toCountry={feedback.toCountry}
            imgUrl={feedback.imgUrl}
            comment={feedback.comment}
          />
        ))}
      </Slider>
    </div>
  )
}

export default FeedbackSlider
